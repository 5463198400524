import React from "react";
// import { useQuery } from "@apollo/client";
import ProductFrame from "./ProductFrameGrid";
// import { GET_DETAILS } from "../../apollo/queries";

export default function ListOfProducts({
  goods,
  page,
  productsPerPage,
  content,
}) {
  const FrameHelper = ({ Frame, good, type }) => {
    return <Frame type={type} good={good} />;
  };

  return (
    <>
      <div className="grid lg:grid-cols-4 grid-cols-1 lg:gap-5 gap-y-4 lg:px-0">
        {content
          .slice((page - 1) * productsPerPage, page * productsPerPage)
          .map((item) => {
            return (
              <FrameHelper
                Frame={ProductFrame}
                key={item.type.strapiId}
                type={item.type}
                good={goods[item.good]}
              />
            );
          })}
      </div>
    </>
  );
}
